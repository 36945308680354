import { createApp } from 'vue';

import VueSweetalert2 from 'vue-sweetalert2';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fab, fas);

import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/styles/main.sass';

import store from './store';
import router from './router';

import Datatable from './components/Datatable.vue';
import NewSelect from './components/NewSelect.vue';
import DatePicker from './components/DateSelector.vue';

import VueGtag from 'vue-gtag';
import Hotjar from 'vue-hotjar';

import App from './App.vue';

// import RollbarPlugin from './rollbar';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueSweetalert2);
// app.use(RollbarPlugin);

if (process.env.VUE_APP_ENV.toLowerCase() === 'production') {
	app.use(VueGtag, { config: { id: 'G-10J2WHV5NK' } });
	app.use(Hotjar, {
		id: '3763241',
		snippetVersion: 6,
		isProduction: true
	});
}

app.component('fa', FontAwesomeIcon);
app.component('datatable', Datatable);
app.component('new-select', NewSelect);
app.component('date-picker', DatePicker);
app.mount('#app');